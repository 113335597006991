<template>
  <div class="register__modal active">
    <div class="img__cont">
      <div class="close__btn" @click="$router.push({ name: 'Home' })">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0022 9.19992L0.80422 0.00190735L0.00437312 0.801755L9.20238 9.99976L0.00390625 19.1982L0.803753 19.9981L10.0022 10.7996L19.2007 19.9981L20.0006 19.1982L10.8021 9.99976L20.0001 0.801756L19.2002 0.00190886L10.0022 9.19992Z" fill="white"/>
        </svg>
      </div>
      <img src="/img/login-photo.jpg" alt="">
    </div>
    <form>
      <div class="wrapp_logo">
        <router-link tag="div" to="/" class="logo"></router-link>
       <SelectorLang color="#930684" style="height: 43px"/>
      </div>
      <strong>{{ $t('registration.title') }}</strong>
      <div class="wrapp_input">

      <span class="input_text">{{$t('registration.country')}}</span>
       <div class="input_row country">
          <v-select 
            v-model="user.country"
            label="countryName"
            :options="countries" 
            :class="{'warning': !regexp.country.status }"
            @input="changeCountryPhoneCode"
          >
          </v-select>
       </div>

        <span class="input_text">{{$t('registration.messageCertificate')}}</span>
        <div class="input_row">
          <input type="text" :class="{'warning': !regexp.firsName.status }" v-model="user.firsName" :placeholder="$t('registration.name')">
          <input type="text" :class="{'warning': !regexp.lastName.status }" v-model="user.lastName" :placeholder="$t('registration.surname')">
        </div>
       
        <div class="input_row">
          <div class="phone-group">
            <div class="phone-group__country-code">
              <v-select
                v-model="user.phoneCode"
                placeholder="+373"
                label="countryPhoneCode"
                :options="countryPhoneCodes"
                :class="{'warning': !regexp.phoneCode.status }"
                @open="adjustDropdownMenuWidth"
              >
                <template #selected-option="option">
                  <span class="flag">{{ option.countryFlag }} </span>
                  <span>{{ option.countryPhoneCode }}</span>
                </template>
                <template v-slot:option="option">
                  <span class="flag">{{ option.countryFlag }} </span>
                  <span>{{ option.countryName }} {{ option.countryPhoneCode }}</span>
                </template>
                <template #open-indicator>
                  <span></span>
                </template>
              </v-select>
            </div>
            <input type="number" @keypress="validatePhone($event)" class="number-input" :class="{'warning': !regexp.phone.status }" v-model="user.phone" :placeholder="$t('registration.phone')">
          </div>
          <input type="text" :class="{'warning': !regexp.email.status }" v-model="user.email" :placeholder="$t('registration.email')">
        </div>

        <span v-if="errorsText.password != ''" class="err">{{ errorsText.password == '1' ? $t('registration.errors.password1') : $t('registration.errors.password2') }}</span>
        <span v-if="progressBarPass != 100 && errorsText.password == '' && !regexp.strongPassword.status" class="err">{{ $t('registration.strongPassword') }}</span>
        <div class="input_row">
          <div class="wrapp_pass">
            <input :class="{'warning': !regexp.password.status || !regexp.strongPassword.status}" v-model="user.password" class="pass" type="password" :placeholder="$t('registration.password')">
            <progress :value="progressBarPass" max="100"></progress>  
          </div>
          <div class="wrapp_pass">
            <input :class="{'warning': !regexp.password.status || !regexp.strongPassword.status}" v-model="user.password2" class="pass" type="password" :placeholder="$t('registration.password2')">
          </div>
        </div>
        

        <div  class="input_row select">
          <v-select :placeholder="$t('registration.proffession.title')" v-model="user.type" 
          :options="[
            { label: $t('registration.proffession.cosmetolog'), code: 'cosmetology'},
            { label: $t('registration.proffession.medic'), code: 'doctor'},
            { label: $t('registration.proffession.other'), code: 'other'}
          ]"
          :class="{'warning': !regexp.type.status }"
          >
          </v-select>
        </div>
        <div v-show="true" class="input_row registration_info__message">
          <span>{{$t('certificateUpload')}}</span>
          <label 
            for="myfile" 
            class="label row_label" 
            :class="{'warning': !regexp.certificate.status }"
          >
            {{ user.files[0] ? user.files[0].name : $t('registration.certificate') }} 
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
              width="30" height="30" viewBox="0 0 512.000000 512.000000"
              class="custom_svg"
              preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" class="fill_color"
                fill="#930684" stroke="none">
                  <path d="M2500 4309 c-29 -12 -67 -46 -83 -74 -9 -15 -13 -322 -17 -1159 l-5
                    -1139 -277 221 c-290 231 -308 242 -383 228 -65 -12 -125 -85 -125 -150 0 -14
                    7 -44 16 -66 14 -34 74 -86 429 -370 227 -181 430 -337 451 -346 83 -35 81
                    -36 553 341 236 189 437 355 446 369 58 88 10 206 -95 237 -70 21 -97 6 -399
                    -236 -154 -124 -283 -225 -286 -225 -2 0 -6 513 -7 1139 -3 1133 -3 1140 -24
                  1167 -11 15 -32 37 -46 47 -31 23 -111 32 -148 16z"/>
                  <path d="M735 2066 c-37 -17 -70 -52 -84 -89 -7 -18 -11 -158 -11 -391 0 -293
                    3 -373 15 -421 45 -172 179 -305 352 -350 86 -23 3020 -23 3106 0 178 46 320
                    193 357 368 7 35 10 181 8 425 -3 356 -4 372 -24 398 -39 53 -71 69 -134 69
                    -63 0 -95 -16 -134 -69 -20 -26 -21 -43 -26 -406 -5 -363 -6 -380 -26 -406
                    -11 -15 -33 -37 -48 -48 -27 -21 -28 -21 -1526 -21 -1498 0 -1499 0 -1526 21
                    -15 11 -37 33 -48 48 -20 26 -21 43 -26 406 -5 363 -6 380 -26 406 -11 15 -32
                  37 -46 47 -34 25 -113 32 -153 13z"/>
              </g>
            </svg>
          </label>
          <!-- accept="image/*,application/pdf" -->
          <input @change="previewFiles" type="file" class="my" id="myfile" name="myfile" accept="image/*,application/pdf">
        </div>
        <div class="row policy_block">
          <label class="wrapp-politics">
            <input v-model="user.accept" class="politics" type="checkbox" required>
            <a 
              class="politics-text"
              :class="{'warning-text': !regexp.accept.status }" 
              :href="user.country.countryCode == 'RO' ? `/terms_ro/terms_ro.pdf` : `/terms_md/terms_${langStorage}.pdf`" 
              target="_blank"
            >
              {{ $t('registration.politics') }}
            </a>
          </label>
          <label class="wrapp-politics right">
            <input v-model="user.accept2" class="politics" type="checkbox" required>
            <a 
              class="politics-text"
              :class="{'warning-text': !regexp.accept2.status }"
              :href="user.country.countryCode == 'RO' ? `/policy_ro/policy_ro.pdf` : `/policy_md/policy_${langStorage}.pdf`"
              target="_blank"
            >
              {{ $t('registration.politics2') }}
            </a>
          </label>
        </div>
  
      </div>

      <div class="btm">
        <div class="login__btn" @click="$router.push('login')">
          {{ $t('registration.login') }}
        </div>
        <button type="button" :disabled="blokedBtn" @click="sendData" style="outline: none;">
          <template v-if="blokedBtn">
            <span>Loading</span>
          </template>
          <template v-else>
           {{ $t('registration.log_in') }}
          </template>
        </button>
      </div>
    </form>
    <!-- modal -->
    <div class="aq__modal__wrp" @click="closeModal" :class="{'active': registrationModal}">
      <div class="aq__modal">
        <div class="top">
          <div class="details">
            <!-- <strong>Adaugă înscriere</strong> -->
          </div>
          <div class="close__btn" @click="registrationModal = false">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.40294 7.99965L0.503906 14.8987L1.10379 15.4986L8.00282 8.59953L14.9019 15.4986L15.5017 14.8987L8.60271 7.99965L15.501 1.10131L14.9012 0.501431L8.00282 7.39976L1.10449 0.501431L0.504607 1.10131L7.40294 7.99965Z" fill="white"/>
            </svg>
          </div>
        </div>
        <div class="container">
          <img class="modal_logo" src="/img/logo2.svg">
          <p 
            v-if="!messageBusyUser.status"
            class="title_end"
            v-html="user.type.code == 'other' ? $t('registration.modal.message2') : $t('registration.modal.message')"
          >
           
        </p>
          <h3 v-else class="title_end">{{$t('registration.messageBusyUser')}} <br> {{messageBusyUser.code}}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {countriesFile} from "@/lang/Countries/";
import SelectorLang from '@/components/SelectorLang';

import { mapActions, mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    SelectorLang
  },
  beforeMount(){
    countriesFile.forEach(country => {
      this.countries.push({
        countryCode: country.code,
        countryName: country.name,
        countryFlag: country.flag,
        countryPhoneCode: country.phoneCode
      });
    });
  },
  data() { 
    return {
      blokedBtn: false,
      user: {
        firsName: "",
        lastName: "",
        phoneCode: {
          countryCode: "MD",
          countryFlag: "🇲🇩",
          countryName: "Moldova, Republic of",
          countryPhoneCode: "+373"
        },
        phone: "",
        email: "",
        type: "",
        lang: "",
        password: "",
        password2: "",
        files: [],
        accept: false,
        accept2: false,
        country: {
          countryCode: '',
          countryName: '',
          countryPhoneCode: '',
          countryFlag: ''
        }
      },
    
      regexp: {
        email: {
          regexp: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
          status: ' '
        },
        firsName: {
          status: ' '
        },
        lastName: {
          status: ' '
        },
        phone: {
          regexp: '^[\\+]?[0-9\\-]*[0-9]$',
          status: ' '
        },
        phoneCode: {
          status: ' '
        },
        type: {
          status: ' '
        },
        password: {
          status: ' '
        },
        strongPassword: {
          status: ' '
        },
        accept: {
          status: ' '
        },
        accept2: {
          status: ' '
        },
        certificate: {
          status: ' '
        },
        country: {
          status: ' '
        }
      },
      errorsText: {
        password: '',
      },
      messageBusyUser: {
        status: false,
        code: '',
      },
      countries: [],
      registrationModal: false
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang'
    }),
    countryPhoneCodes () {
      return countriesFile.map(
        country => ({
          countryPhoneCode: country.phoneCode, 
          countryName: country.name, 
          countryCode: country.code,
          countryFlag: country.flag
        })
      )
    },
    progressBarPass() {
      let progress = 0;
      if(this.user.password.length >= 3) progress = 35;
      if(this.user.password.length >= 5) progress = 50;
      if(this.user.password.length >= 6) progress = 80;
      if(this.user.password.length >= 6 && /[a-zA-ZА-Яа-яёЁ]/.test(this.user.password)&& /[0-9]/.test(this.user.password)) progress = 100;
      if(this.user.password.length >= 6 && /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(this.user.password)) progress = 100;
      return progress;
    }
  },
  methods: {
    adjustDropdownMenuWidth() {
      if (!document) return
      // we have no access to v-select dropdown element. Besides, the dropdown is inserted after the 
      // click on the select. So we need to observe the body and wait for the dropdown to be inserted
      // and then set the width of the dropdown to the width of the phone input
      const observer = new MutationObserver((mutations, observer) => {
        const phoneGroup = document.querySelector('.phone-group');
        const dropdownMenu = document.querySelector('.vs__dropdown-menu');

        if (phoneGroup && dropdownMenu) {
          dropdownMenu.style.width = `${phoneGroup.offsetWidth}px`;
          observer.disconnect();
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    validatePhone(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && (charCode !== 43 || charCode === 46)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    googleEventRegistrationComplete(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'Register_Complete'
      });
    },
    ...mapActions({
      setLang: 'languages/setLang'
    }),
    previewFiles(event) {
      this.user.files = event.target.files
    },
    async sendData(){
      if(this.validator()) {
        this.blokedBtn = true;
        this.user.lang = this.langStorage;
        let formData = new FormData();
        formData.append('username', this.user.email.toLowerCase());               
        formData.append('email', this.user.email);
        formData.append('password', this.user.password);
        formData.append('first_name', this.user.firsName);
        formData.append('last_name', this.user.lastName);
        formData.append('profile.name', `${this.user.firsName} ${this.user.lastName}`);
        formData.append('profile.description', this.user.lang);
        formData.append('profile.phone', this.user?.phoneCode?.countryPhoneCode + this.user.phone);
        if(this.user.files.length) {
          formData.append('profile.file',  this.user.files[0]);     
        }      
        formData.append('profile.city',  this.user.country.countryName);
        formData.append('profile.specialization', this.user.type.code);
        formData.append('profile.type', this.user.type.code);
        formData.append('profile.shop_is_active', true);
        formData.append('profile.utm_tags', this.$store.getters['utmQuery/strUtm']);

        try {
          const response = await fetch(`${domain}/accounts/users/`, {
            method: 'POST',   
            body: formData
          });
          this.blokedBtn = false;                 
          if(response.ok){
            this.messageBusyUser.status = false;
            this.registrationModal = true;
            setTimeout(() => { this.$router.push({name: 'login', params: {lang: this.langStorage}});  }, 6000);
            this.googleEventRegistrationComplete();
          }
          else {
            this.messageBusyUser.code = `status code: ${response.status}`;
            this.messageBusyUser.status = true;
            this.registrationModal = true;
            console.error(response);
          }
        }
        catch(err) {
          console.error(err);
          alert(err);
        }   
      }
    },
    validator() {
      this.regexp.email.status = this.regexp.email.regexp.test(this.user.email);
      this.regexp.firsName.status = this.user.firsName.length >= 2;
      this.regexp.lastName.status = this.user.lastName.length >= 2;
      this.regexp.type.status = this.user.type.code != undefined;
      this.regexp.password.status = this.user.password.length >= 6 && this.user.password == this.user.password2;
      this.errorsText.password = this.user.password == this.user.password2 ? this.user.password.length >= 6 ? '': '1' : '2';
      this.regexp.strongPassword.status = this.errorsText.password == '' && this.progressBarPass == 100;
      this.regexp.accept.status = this.user.accept;
      this.regexp.accept2.status = this.user.accept2;
      this.regexp.certificate.status = this.user.files.length ? true : this.user.type.code == 'other';
      this.regexp.phone.status = this.user.phone.length >= 6; 
      this.regexp.country.status = this.user.country.countryName != ''; 
      this.regexp.phoneCode.status = this.user.phoneCode.countryPhoneCode != '';

      for (let key in this.regexp) {
        if(this.regexp[key].status == false){
          return false;
        }
      }

      return true;
    },
    onSelect(e){
      this.user.country = e;
    },
    closeModal(e){
     if(e.target.classList == 'aq__modal__wrp active'){
      this.registrationModal = false;
     }
    },
    async filterOptions(search) {
      return this.countryPhoneCodes.filter(country => {
        if (country.countryName.includes(search) || country.countryPhoneCode.includes(search)) {
          return country
        }
      })
    },
    changeCountryPhoneCode (e) {
      this.user.phoneCode = e;
    }
  }
}
</script>

<style lang="scss">
// hide arrows for input[type=number] on this page
input.number-input::-webkit-outer-spin-button,
input.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.number-input[type=number] {
  -moz-appearance: textfield;
}

.phone-group {
  display: flex;
  width: 49%;
  @media (max-width: 620px) {
    width: 100%;
  }
  &__country-code {
    width: 100%;
    max-width: 90px;
    .flag {
      margin-right: 5px;
    }
    .v-select {
      height: 100%;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 1px solid gray;
    }
    .vs__selected {
      height: 100%;
      margin: 0;
      padding: 0;
    }
    .vs__dropdown-toggle {
      padding: 0;
    }
    .vs__selected-options {
      justify-content: center;
    }
    .vs__actions {
      padding: 0;
    }
    .vs__search {
      width: 100% !important;
    }
    .vs__dropdown-menu {
      width: 100%;
    }
  }
  .number-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100% !important;
  }
}


.register__modal  {
  .top {
    padding: 0;
    .close__btn {
      margin-right: 15px;
      margin-top: 15px;
    }
  }
  .aq__modal__wrp {
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .modal_logo {
        position: inherit;
        width: 40%;
      }
      width: 100%;
      .title_end {
        text-align: left;

        line-height: 30px;
        padding: 60px;
        @media (max-width:1024px) {
          padding: 20px;
        }
      }
    }

  }
}
@media(min-width: 1200px){
  .registration_info__message {
    display: flex;
    // align-items: center;
    align-self: flex-start;
    color: #930684;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    span {
      position: absolute;
      top: calc(100% - 100px);
      right: 0;
      left: 0;
      opacity: 0;
      text-align: center;
      pointer-events: none;
      padding: 10px 16px 15px;
      background-color: #f2f2f2;
      border-top: 1px solid #ababab;
      box-shadow: 0 10px 10px rgb(0 0 0 / 7%);
      transition: .2s ease;
      z-index: 2;
    }
    &:hover {
      span {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
}
@media(max-width:768px){
      .registration_info__message {
    display: flex;
    // align-items: center;
    align-self: flex-start;
    color: #930684;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    span {
      margin-bottom: 1em;
      opacity: 1;
      text-align: center;
      font-size: 13px;
      pointer-events: none;
      padding: 10px 16px 15px;
      // background-color: #f2f2f2;
      // border-top: 1px solid #ababab;
      // box-shadow: 0 10px 10px rgb(0 0 0 / 7%);
      transition: .2s ease;
      z-index: 2;
    }
  }
}
@media(max-width: 620px){
  .wrapp_input .input_row .v-select{
    height: 30px !important;
  }
  .wrapp_input .input_row .pass{
    margin-bottom: 5px;
  }
}
@media(max-width: 430px){
  .register__modal form strong {
    font-size: 22px;
    margin-bottom: 0px !important; 
    line-height: 20px;
  }
}
@media(min-width: 1365px){
  .register__modal form{
    padding: 30px;
  }
  .register__modal form strong{
    margin-bottom: 28px;
  }
}

  .row_label{
    display: flex !important;
    flex-direction: row;
    justify-content: center;
  }
  .custom_svg{
    margin-left: 1em;
    margin-top: 0.6em;
  }
  .label:hover .custom_svg .fill_color{
    fill: white;
  }
 .register__modal {
  #myfile {
    width: 0;
  }
  .label {
    margin-bottom: 50px;
  }

  .wrapp_input {
    .err {
      font-size: 12px;
      font-weight: 800;
      padding-left: 10px;
      color: #ff00009e;
    }
  }
 }

.wrapp_input {
  width: 88%;
  .vs__clear {
    display: none;
  }
  .input_row {
    display: flex;
    justify-content: space-between;
    height: 50px;
    margin-bottom: 30px;
    
   input::-webkit-input-placeholder {
     color: #ADADBD;
   }

    input {
      width: 49%;
      border-radius: 5px;
      border: none;
      padding-left: 10px;
      outline: none;
    }

    .v-select {
      width: 100%;
      background-color: white;
      border-radius: 5px;
      div{
        border: none;
        height: 100%;
      }
    }
  }

  .my {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .label {
    width: 180px;
    height: 50px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    display: block;
    font: 14px/50px "IBM Plex Sans";
    transition: all 0.18s ease-in-out;
    border: 1px solid #930684;
    color: #930684;
    width: 100%;
  }

  .label:hover {
    color: white;
    background: #930684;
  }

}

  .register__modal .logo {
    background: url("/img/logo2.svg") no-repeat !important;
    width: 150px !important;
    height: 50px !important;
    cursor: pointer;
  }



  .wrapp_logo {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5%;
    height: 50px;  
  }

  .register__modal {
    .wrapp-politics {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .politics {
        width: 20px;
        height: 20px;
      }
      .politics-text {
        color: #891E82;
        padding-left: 15px;
        text-decoration: underline;
      }
    }
    .policy_block {
      display: flex;
      justify-content: space-between;
      @media(max-width: 430px){
        display: block;
      }
    }
    .aq__modal__wrp {
      .modal_logo {
        position: relative;
        left: -35px;
      }
    }
  }

  .register__modal .warning {
    border: 1px solid rgba(255, 0, 0, 0.493) !important;
    background-color: rgba(255, 0, 0, 0.034) !important;
  }
  .register__modal .warning-text {
    color: red !important;
  }

  .register__modal .wrapp_pass {
    width: 49%;
    input {
      width: 100%;
      height: 100%;
    }

    progress {
      width: 99%;
      height: 5px;
      @media(max-width:430px){
        height: 3px;
        opacity: 0.5;
      }
    }
    @media(max-width:620px){
      width: 100%;
    }
  }

  .register__modal {
    .input_text {
      display: inline-block;
      font-size: 13px;
      font-weight: 900;
      margin-bottom: 5px;
      opacity: 0.5;
    }
  }


  @media (max-width: 1240px) {
    .register__modal form {
      padding: 20px !important;
    }
  }
  @media (max-width: 1024px) and (min-width: 430px) {
    .register__modal form strong {
      font-size: 50px;
    }
    .register__modal .img__cont .close__btn {
      left: 30px;
      top: 30px;
    }
    .wrapp_input,.register__modal form .btm {
      width: 100%;
    }
    .register__modal form {
      width: 60%;
    }
    .register__modal .img__cont {
      width: 40%;
    }
  }
  @media (max-width: 1600px) and (min-width: 430px) {
    .register__modal form .btm button {
      padding: 0 14px !important;
      font-size: 14px !important;
      line-height: 60px !important;
    }
  }

  @media (max-width: 620px) {
    .wrapp_input {
      width: 100%;
    }
    .wrapp_input .input_row {
      flex-direction: column;
      height: unset;
      margin-bottom: 0;

      .v-select {
        height: 40px;
      }
      input {
        width: 100%;
        height: 30px;
        font-size: 15px;
        margin-bottom: 10px;
      }

      &.country {
        margin-bottom: 15px;
        .vs__selected {
          margin-top: 9px;
        }
      }

    }
    .input_row.select {
      margin-bottom: 14px;
    }
    .register__modal form .btm {
      margin-top: 15px !important;
    }
    .register__modal form .logo {
      margin: 0 !important;
    }
  }

  @media(max-width: 430px){
    .register__modal.active {
      position: inherit;
    }
    .register__modal .label{
      margin-bottom: 17px;
    }
    .register__modal .wrapp-politics{
      margin-bottom: 5px;
    }
    .register__modal form strong{
      margin-bottom: 12px;
    }
    .register__modal form{
      padding: 10px !important;
    }
  }

</style>