<template>
  <div class="success__slider__wrp">
    <!--    <div class="fig"></div>-->
    <div class="top">
      <h2>{{ $t('personalSlider.title.text') }}<b>{{ $t('personalSlider.title.color') }}</b></h2>
      <div class="arrows">
        <!-- <div :style="firstSreen" @click="$refs.carouselPersonal.prev()" class="arr prev">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.1587 32.5802C19.8317 32.2617 19.8317 31.7363 20.1587 31.4178L42.7153 9.44052C43.0304 9.13352 43.5328 9.13351 43.8479 9.44052C44.1748 9.75903 44.1748 10.2844 43.8479 10.6029L21.2912 32.5802C20.9761 32.8872 20.4738 32.8872 20.1587 32.5802Z" fill="#000001"/>
            <path d="M43.8479 54.5593C44.1748 54.2408 44.1748 53.7154 43.8479 53.3969L21.2846 31.4132C20.9695 31.1062 20.4672 31.1062 20.1521 31.4132C19.8252 31.7317 19.8252 32.2571 20.1521 32.5756L42.7154 54.5593C43.0305 54.8663 43.5328 54.8663 43.8479 54.5593Z" fill="#000001"/>
          </svg>
        </div>
        <div :style="lastSreen" @click="$refs.carouselPersonal.next()" class="arr next">
          <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.8413 32.5802C44.1683 32.2617 44.1683 31.7363 43.8413 31.4178L21.2847 9.44052C20.9696 9.13352 20.4672 9.13351 20.1521 9.44052C19.8252 9.75903 19.8252 10.2844 20.1521 10.6029L42.7088 32.5802C43.0239 32.8872 43.5262 32.8872 43.8413 32.5802Z" fill="#000001"/>
            <path d="M20.1521 54.5593C19.8252 54.2408 19.8252 53.7154 20.1521 53.3969L42.7154 31.4132C43.0305 31.1062 43.5328 31.1062 43.8479 31.4132C44.1748 31.7317 44.1748 32.2571 43.8479 32.5756L21.2846 54.5593C20.9695 54.8663 20.4672 54.8663 20.1521 54.5593Z" fill="#000001"/>
          </svg>
        </div> -->

      </div>
    </div>
    <div class="success__slider">
      <VueSlickCarousel
          v-if="reviews.length"
          ref="carouselPersonal"
          :arrows="true"
          :infinite="false"
          :initialSlide="0"
          :slidesToShow="slidesToShow"
          @beforeChange="ChengeScreen"
          :responsive="responsiveOptions"
          :autoplay="false"
          :autoplaySpeed="6000"
          :pauseOnHover="false"
      >
        <div v-for="(item, ind) in reviews" :key="item.id">
          <div class="card">
            <div class="top__img" @click="$event.x == targetClick ? indItem = ind : ''" @mousedown="targetClick = $event.x">
              <div class="shadow"></div>
              <div class="img__cont">
                <img :src="item.image_thumbnail ? item.image_thumbnail : item.image " alt="doctor-image">
              </div>
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="function">{{ item.specialization }}</div>
            <vue-custom-scrollbar class="slider-desc inner-p" :settings="settings" @ps-scroll-y="scrollHanle" v-html="item.description">
            </vue-custom-scrollbar>
            <!-- <div id="slider-desc" class="slider-desc inner-p" v-html="item.description">
              
            </div> -->

            <!-- <a class="view">
              {{ selectLang.specialists.btn }}
              <img src="/img/success-forward.svg" alt="arrow-img">
            </a> -->
          </div>
        </div>
        <template #prevArrow="arrowOption">
          <div class="arr prev">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.1587 32.5802C19.8317 32.2617 19.8317 31.7363 20.1587 31.4178L42.7153 9.44052C43.0304 9.13352 43.5328 9.13351 43.8479 9.44052C44.1748 9.75903 44.1748 10.2844 43.8479 10.6029L21.2912 32.5802C20.9761 32.8872 20.4738 32.8872 20.1587 32.5802Z" fill="#000001"/>
              <path d="M43.8479 54.5593C44.1748 54.2408 44.1748 53.7154 43.8479 53.3969L21.2846 31.4132C20.9695 31.1062 20.4672 31.1062 20.1521 31.4132C19.8252 31.7317 19.8252 32.2571 20.1521 32.5756L42.7154 54.5593C43.0305 54.8663 43.5328 54.8663 43.8479 54.5593Z" fill="#000001"/>
            </svg>
          </div>
        </template>
        <template #nextArrow="arrowOption">
          <div class="arr next">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M43.8413 32.5802C44.1683 32.2617 44.1683 31.7363 43.8413 31.4178L21.2847 9.44052C20.9696 9.13352 20.4672 9.13351 20.1521 9.44052C19.8252 9.75903 19.8252 10.2844 20.1521 10.6029L42.7088 32.5802C43.0239 32.8872 43.5262 32.8872 43.8413 32.5802Z" fill="#000001"/>
              <path d="M20.1521 54.5593C19.8252 54.2408 19.8252 53.7154 20.1521 53.3969L42.7154 31.4132C43.0305 31.1062 43.5328 31.1062 43.8479 31.4132C44.1748 31.7317 44.1748 32.2571 43.8479 32.5756L21.2846 54.5593C20.9695 54.8663 20.4672 54.8663 20.1521 54.5593Z" fill="#000001"/>
            </svg>
          </div>
        </template>

      </VueSlickCarousel>
    </div>
    <CoolLightBox
        :items="lightBox"
        :index="indItem"
        @close="indItem = null">
    </CoolLightBox>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import CoolLightBox from 'vue-cool-lightbox'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    VueSlickCarousel,
    vueCustomScrollbar,
    CoolLightBox
  },
  props: {
    slidesToShow: {
      type: Number,
      default: 3
    },
    infinite: {
      type: Boolean,
      default: false
    },
    countSlider: {
      type: Number,
      default: 4
    },
    reviews:{
      type: Array,
      default: () => []
    },
  },
  data:()=>({
    targetClick: 0,
    indexScreenPersonal: 0,
    desktoppeSize: 2,
    responsiveOptions: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
    ],
    lightBox: [],
    indItem: null,
    settings: {
    }
  }),
  mounted(){
    this.reviews.forEach(e => {
      this.lightBox.push(e.image);
    })
  },
  methods: {
    ChengeScreen(e, i){
      this.indexScreenPersonal = i;
    },
    scrollHanle(evt) {
      console.log(evt)
    }
  },

  computed: {
    firstSreen(){
      return this.indexScreenPersonal == 0 ? {'opacity': 0.5} : {'opacity': 1};
    },
    lastSreen(){
      return this.indexScreenPersonal + 1 == this.countS ? {'opacity': 0.5} : {'opacity': 1};
    },
    countS(){
      if(window.innerWidth > 430) {
        return this.desktoppeSize;

      }
      else {
        return this.countSlider;
      }
    },
  }
}
</script>

<style lang="scss">

.specialist_page {
  @media (max-width: 576px) {
    padding: 0 15px;

    .specialist__cnt {
      flex-direction: column;

      .about__col {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.success__slider__wrp  {

  @media (max-width: 576px) {
    .top h2 {
      width: 100%;
    }
  }
  .success__slider {
    width: 100%;
  }
}

.slick-slider {
  position: relative;
  padding-bottom: 20px;

  @media (max-width: 576px) {
    padding-top: 40px;
    padding-bottom: 64px;
  }

  .ps__rail-y {
    opacity: .9;
    display: block;
    background-color: #eee;
    border-radius: 12px;
  }
  .ps__thumb-y {
    width: 11px;
    background-color: #999;
  }

  .slick-prev {
    right: 70px;
    left: auto;
    top: -18%;
    width: auto;
    height: auto;
    z-index: 1;

    &.slick-disabled {
      opacity: 0.5;
    }

    &::before {
      display: none;
    }

    @media (max-width: 576px) {
      top: auto;
      bottom: -8%;
      right: auto;
      left: 30%;
    }
  }

  .slick-next {
    right: 0;
    left: auto;
    top: -18%;
    width: auto;
    height: auto;
    z-index: 1;

    &.slick-disabled {
      opacity: 0.5;
    }

    &::before {
      display: none;
    }

    @media (max-width: 576px) {
      top: auto;
      bottom: -8%;
      left: auto;
      right: 30%;
    }
  }
}

.success__slider__wrp .success__slider .card .top__img .img__cont {
  cursor: pointer;
  // max-height: 385px;
  width: 320px;
  height: 320px;
  @media(max-width:1400px){
    width: 300px;
    height: 300px;
  }
  @media(max-width:768px){
    width: 250px;
    height: 250px;
  }
}
.success__slider__wrp .success__slider .card .top__img .shadow {
  width: 320px;
  height: 320px;
  @media(max-width:1400px){
    width: 300px;
    height: 300px;
  }
  @media(max-width:768px){
    width: 250px;
    height: 250px;
  }
}

body.night .success__slider__wrp .fig, body.night .faq__wrp .txt .fig {
  background-size: cover !important;
}

.success__slider__wrp .slider-desc {
  height: 110px;
  padding-right: 20px;

  scrollbar-color: #F5F4FB transparent;     /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;  /* толщина */

  &::-webkit-scrollbar { /* полоса прокрутки (скроллбар) */
    width: 5px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb { /* ползунок скроллбара */
    background-color: #F5F4FB;
    border-radius: 2em;
    box-shadow: inset 1px 1px 10px #E6DEDE;
  }
}

@media(max-width: 430px){
  // .success__slider__wrp .success__slider .card p,.success__slider__wrp .success__slider .card .slider-desc {
  //   min-height: 175px;
  // }
  .success__slider__wrp {
    padding: 0 16px 45px;
  }
}


</style>